import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';

import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    return (
        <Layout>
            <Seo title="Marketing" />
            <div className="marketing-page">
                <Container className="pink-blob container-1">
                    <Row>
                        <Col className="mobile-only">
                            <img
                                src={require('../../images/marketing/Group 7118.png')}
                                alt="create and view analytics for marketing campaigns"
                            />
                        </Col>
                        <Col className="one-half max-width-600">
                            <h1>Your Very Own IT Marketing Hub</h1>
                            <p className="hero-subtext">
                                Complete with everything you need to create
                                super sweet marketing
                            </p>
                        </Col>
                        <Waypoint
                            onEnter={() => {
                                document
                                    .querySelector('.slide-in-container')
                                    .classList.add('animate');
                            }}
                        />
                        <Col className="one-half">
                            <div className="slide-in-container">
                                <img
                                    className="slide-1"
                                    src={require('../../images/marketing/Group 6738.png')}
                                    alt="build campaign"
                                />
                                <img
                                    className="slide-2"
                                    src={require('../../images/marketing/Group 6006.png')}
                                    alt="campaign builder options"
                                />
                                <img
                                    className="slide-3"
                                    src={require('../../images/marketing/Group 4604.png')}
                                    alt="color and font options"
                                />
                                <img
                                    className="slide-4"
                                    src={require('../../images/marketing/Group 4773.png')}
                                    alt="pie chart for opens"
                                />
                                <img
                                    className="slide-5"
                                    src={require('../../images/marketing/Group 6009.png')}
                                    alt="campaign preview"
                                />
                                <img
                                    className="slide-6"
                                    src={require('../../images/marketing/Group 2464.png')}
                                    alt="pie chart for clicks"
                                />
                                <img
                                    className="slide-7"
                                    src={require('../../images/marketing/Group 6008.png')}
                                    alt="campaign analytics graph"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="grey-swish-background container-2 centered-text ">
                    <Row>
                        <Col className="max-width-900 margin-auto">
                            <h3>Level up your IT marketing</h3>
                            <p className="large-p-text wrapLineSib">
                                You’re used to being on the front line of
                                generating new revenue. You’ve probably heard
                                more than one variation of “this lead isn’t an
                                SQL.”
                            </p>
                            <p className="large-p-text wrapLineSib">
                                That’s a thing of the past with GlassHive.
                            </p>
                            <p className="large-p-text wrapLineSib">
                                GlassHive gives you the complete picture of your
                                marketing efforts. When you hand that lead off
                                to your sales team, you know its qualified.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#next-generation-email-builder-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="next-generation-email-builder-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc ">
                            <h3 className="centered-mobile">
                                Next-generation email builder
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Build completely custom and branded emails with
                                our intuitive and simple-to-use email builder.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Whether you use one of our pre-made templates or
                                design one yourself, all emails sent through
                                GlassHive are mobile-responsive.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel second-col width-55-perc">
                            <video
                                src={require('../../videos/next-generation-email-builder.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="next-generation-email-builder-video hide-on-mobile"
                                id="next-generation-email-builder-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-50-neg"
                                src={require('../../images/marketing/Group 6028.png')}
                                alt="campaign builder"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#marketing-plans-video')
                                .play();
                        }}
                    />
                    <Row className="marketing-plans-row flexed-mobile">
                        <Col className="one-half flexed-order-1">
                            <video
                                src={require('../../videos/marketing-plans.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="marketing-plans-video hide-on-mobile"
                                id="marketing-plans-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-100-neg"
                                src={require('../../images/marketing/Group 6468.png')}
                                alt="scheduled campaigns calendar"
                            />
                        </Col>
                        <Col className="one-half second-col max-width-500">
                            <h3 className="centered-mobile">
                                Comprehensive & automated marketing plans
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Plan out months or years worth of marketing
                                content through our marketing plan tool.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Schedule the exact date, time and topic of every
                                email you want to send out ahead of time. Any
                                marketing content you need to get started
                                already exists in the GlassHive collateral
                                library, and all calls to action are
                                automatically linked to the collateral piece of
                                your choosing.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Just pick a start date and watch the leads roll
                                in.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-5">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#built-in-easy-ab-testing-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="built-in-easy-ab-testing-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Built-in and easy to use A/B testing
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Automatic A/B testing ensures you’re always
                                sending out the strongest email.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                With GlassHive’s built-in A/B testing, you can
                                test different subject lines or content on a
                                portion of your list and send out the best
                                performer to your full list automatically.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col ">
                            <video
                                src={require('../../videos/built-in-easy-ab-testing.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="built-in-easy-ab-testing-video hide-on-mobile"
                                id="built-in-easy-ab-testing-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-50-neg scale-up"
                                src={require('../../images/marketing/Group 6827.png')}
                                alt="a/b testing open and click rate comparison"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#share-and-analyze-collateral-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="share-and-analyze-collateral-row flexed-mobile">
                        <Col className="one-half centered-text flexed-order-1">
                            <video
                                src={require('../../videos/share-and-analyze-collateral.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="share-and-analyze-collateral-video hide-on-mobile"
                                id="share-and-analyze-collateral-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-50-neg scale-up"
                                src={require('../../images/marketing/Group 7119.png')}
                                alt="attach collateral to emails"
                            />
                        </Col>
                        <Col className="one-half max-width-480 second-col">
                            <h3 className="centered-mobile">
                                Share & analyze your marketing collateral
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive’s collateral library lets you
                                instantly publish all your marketing materials
                                so you can quickly and easily attach them to
                                future campaigns.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Your sales team has access to the same
                                collateral library, so you can analyze valuable
                                data points to assess the performance of each
                                piece of collateral and consistently improve.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-7">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#pane-of-glass-video')
                                .play();
                        }}
                    />
                    <Row className="pane-of-glass-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                All of your data in one pane of glass
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                One of the core tenets of GlassHive is
                                visibility. From day one, GlassHive as a
                                platform was developed to offer comprehensive
                                visibility that could be leveraged to improve
                                sales and marketing efforts.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                That’s why we made your home dashboard a
                                snapshot of all the analytics you need to assess
                                and improve your marketing.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/pane-of-glass.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="pane-of-glass-video hide-on-mobile"
                                id="pane-of-glass-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-50-neg"
                                src={require('../../images/marketing/Group 6472.png')}
                                alt="dashboard analytics"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-8">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#premade-marketing-video')
                                .play();
                        }}
                    />
                    <Row className="premade-marketing-row flexed-mobile">
                        <Col className="one-half centered-text flexed-order-1">
                            <video
                                src={require('../../videos/premade-marketing.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="premade-marketing-video hide-on-mobile"
                                id="premade-marketing-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile margin-bottom-50-neg"
                                src={require('../../images/marketing/Group 6550.png')}
                                alt="premade marketing material"
                            />
                        </Col>
                        <Col className="one-half max-width-500 second-col">
                            <h3 className="centered-mobile">
                                Pre-made marketing material that’s proven to
                                work
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                When you sign up for GlassHive, you instantly
                                get access to a full library of marketing
                                material. Whitepapers, eBooks, videos and more
                                covering topics from IT budget to
                                virtualization.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                With these resources, you can get to marketing
                                quicker than on any other platform with
                                specialized content that is proven to work.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-9">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#easily-build-core-marketing-pieces-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="easily-build-core-marketing-pieces-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Easily build your Core Marketing Pieces
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive has built-in collateral, landing page
                                and email building tools. You can build your own
                                custom-branded templates so that every piece of
                                marketing material you need can be easily and
                                quickly branded, published and ready to use in
                                your next campaign.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                And if you use vendor marketing materials, your
                                vendor partners can share their collateral
                                library with you so you have one place to go to
                                for all of your marketing assets.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/easily-build-core-marketing-pieces.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="easily-build-core-marketing-pieces-video hide-on-mobile"
                                id="easily-build-core-marketing-pieces-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/marketing/Group 7121.png')}
                                alt="custom branding tools"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-10">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.join-glasshive-callout')
                                .classList.add('animate');
                        }}
                    />
                    <Row>
                        <Col className="three-fourth">
                            <JoinGlassHiveCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
